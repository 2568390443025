@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Cairo', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Background styles */
.log-clip{
  clip-path: polygon(75% 0, 100% 25%, 100% 0);
}
.con-login {
  background-color: #ffffff;
  background-image: 
      radial-gradient(at 47% 33%, hsl(222.15, 66%, 53%) 0, transparent 59%), 
      radial-gradient(at 82% 65%, hsl(180.00, 0%, 100%) 0, transparent 55%);
}

/* Glassmorphism card effect */
.card {
  /* backdrop-filter: blur(1px) saturate(64%);
  -webkit-backdrop-filter: blur(15px) saturate(64%);
  background-color: rgba(75, 123, 236, 0.34);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125); */
  backdrop-filter: blur(4px) saturate(64%);
  -webkit-backdrop-filter: blur(15px) saturate(64%);
  background-color: rgba(255, 255, 255, .5)
}


.trClubHidden {
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}
.trClubShow {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);}


.tc h1{
font-size : 22px;
font-weight : 600;
}
.tc h2 {
font-size : 20px;
font-weight : 500;
}
.tc h3  {
font-size : 18px;
font-weight : 400;
}

.tc p , .tc div{
margin-right: 13px;
}
.tc ol li  {
list-style-type: capital-alpha;
margin-right : 50px
}
 .tc ul li{
list-style-type: circle;
margin-right : 50px
}
/* Generated by https://generator.ui.glass/ */

@tailwind base;
@tailwind components;
@tailwind utilities;